@import '../index.scss';

.homeContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: auto 0;
}
.picContainer {
    & img {
        max-width: 100%;
    }
}

.pinPlaceholder {
    color: var( --color--grey-20 );
}

.unlockedApp {
    font-size: 20px;

    &:hover {
        transform: scale(1.2);
    }
}