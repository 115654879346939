.bookContainer {
    justify-content: center;
    align-items: center;
    margin: auto;

    position: relative;
    transform: perspective(200em) rotateX(25deg);
    box-shadow: -1.4em 1.7em 0.3em -0.3em rgba(0,0,0,0.8), -1.6em 1.8em 0.9em -0.2em rgba(0,0,0,0.5), 0.3em 1.9em 1.3em rgba(0,0,0,0.3);
    border-top-right-radius: 0.4em;
    
    .demoPage {
        background-color: white;

        & .pageContent {
            width: inherit;

            & .spacer1rem {
                height: 3rem;
            }

            & .recipeImg {
                width: 90%;
                margin: 1rem;
            }

            & .spacer {
                width: 40%;
            }
        
            & .bookContent {
                font-size: 1.2rem;
            }
        }

        & .coverImg {
            width: 100%;
            height: 100%;
        }
    }
}
