.historyContainer {
    display: flex;
    justify-content: center;

    & .carousel-root {
        & .slide {
            & .label {
                position: absolute;
                left: 15%;
                right: 15%;
                bottom: 5%;
                background-color: white;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);
                border-radius: 5px;
                padding: 0.5rem;

                display: flex;
                justify-content: center;

                &:hover {
                    transform: scale(1.2);
                }

                & a {
                    text-decoration: none;
                    color: black;
                }
            }
        }
    }
}