@import '../index.scss';

:root {
    @each $col, $value in map-get($colors, "light-mode") {
		--color--#{$col}: #{$value};
	}
}

$fontSizeHeading: 32px;
$fontSizeBody: 16px;

.appContainer {
    width: 100vw;
    height: 100vh;
    
    background-color: var( --color--background );

    display: grid;

    grid-template-rows: 1fr 8fr;

    .card {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: var( --color--white );
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);

        margin: 1rem auto;
        width: 80%;
        text-align: center;
        justify-content: center;
        align-items: center;

        @include media( 'tablet', 'down' ) {
            max-width: 100%;
        }
    }

    .navMenu {

        &__container {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr;
            list-style: none;

            &__link {

                &:hover {
                    transform: scale(1.2);
                    opacity: 70%;
                }

                & a {
                    font-size: 24px;
                    text-decoration: none;
                }
            }
        }
    }
}