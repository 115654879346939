@use "sass:math";
@use "sass:selector";
@use "sass:list";

// Responsive breakpoints mixin
@mixin media( $breakpoint, $direction: "up" ) {

	$dir: "min";

	@if $direction == down {
		$dir: "max";
	}

	@if map-has-key($grid-breakpoints, $breakpoint) {
		$breakpoint-value: map-get($grid-breakpoints, $breakpoint);

		@if $direction == down {
			$breakpoint-value: $breakpoint-value - 1px;
		}

		@media (#{$dir}-width: $breakpoint-value) {
			@content;
		}
	} @else {

		@media (#{$dir}-width: $breakpoint) {
			@content;
		}
	}
}

@function rem($pixels, $context: $baseFontSize) {
	$remSize: $size / 16px;
	@return #{$remSize}rem;
}